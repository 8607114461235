import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './Components/Home'
import Craft from './Stitching/Craft'
import './Styling/Stitching.scss'
import './Styling/index.scss';

ReactDOM.render(
	<Router>
		<Switch>
			<Route path='/stitch'>
				<Craft />
			</Route>
			<Route path='/'>
				<Home />
			</Route>
		</Switch>
	</Router>,
	document.getElementById( 'root' )
)
