import React from 'react'
import { COMIC_TOP, COMIC_BOTTOM, ABOUT } from '../Constants'

const link = (label, url) => <a target='_blank' rel="noopener noreferrer" href={url}>{label}</a>

const About = () => (
    <div className='wrap'>
      <div className='comic-row'>
        <img src={COMIC_TOP} className='comic' alt='comic'/>
        <h1 className='headline pink'>
          I’m a passionate engineer with a human-oriented background.
        </h1>
      </div>
      <p className='about-text'>
        From a young age, I was most interested in the intricacies of human relationships, both individually and as parts of the larger culture. I followed this path through two degrees and, as a Bay Area native, found myself entering the tech industry via People Ops roles. It took a couple more years for me to realize that I wasn't satisfied at work. I wanted to explore a completely new way of thinking, learning, and creating. 
      </p>
      <p className='about-text'>
        In 2018, I fell in love with programming, quit my job to pursue it full time, and never looked back. Software engineering is the job I'd always been looking for. It lets me work through things as a visual thinker and build something out of nothing. I love the logical and technical challenges I face every day, and the knowledge that there are infinite new things to learn.
      </p>
      <p className='about-text'>Since I started in this field, I like to help coach other prospective engineers who may be at an inherent disadvantage in the field, with organizations such as {link('Rails Girls', ABOUT.railsGirls)} and {link("St. Mungo's Recovery College", ABOUT.stMungos)}, and, since moving to Portland, the {link("PDXWIT Mentorship Program", ABOUT.mentorship)}. Because no matter how much I love diving into code every day, what matters most is using that power to help people.
      </p>
      <div className='comic-row' id='bottom'>
      <h1 className='headline pink' id='right'>
        I believe we have a responsibility to use technology for social good.
      </h1>
        <img src={COMIC_BOTTOM} className='comic' id='bottom' alt='comic'/>
      </div>
    </div>
)

export default About