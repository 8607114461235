import React from 'react'

const Heart = () => (
  <svg 
    version="1.0" 
    xmlns="http://www.w3.org/2000/svg"
    width="25px" 
    height="25px" 
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet" 
    id="heart"
  >
    <g 
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={"#ffff"} 
      stroke="none" 
      id="heart"
    >
      <path d="M1140 4665 c-574 -95 -988 -493 -1112 -1069 -32 -147 -31 -417 1
      -566 42 -197 129 -392 259 -580 99 -144 172 -230 342 -404 229 -234 457 -432
      959 -831 145 -115 423 -337 618 -493 l354 -282 222 178 c122 98 389 310 592
      472 636 505 874 709 1125 966 343 352 520 644 591 974 30 140 33 409 5 545
      -59 297 -194 548 -400 743 -182 171 -401 284 -661 338 -125 26 -414 27 -533 1
      -354 -78 -652 -289 -874 -621 -33 -50 -64 -91 -68 -91 -4 0 -35 41 -69 92
      -219 328 -517 540 -866 618 -100 23 -377 28 -485 10z"/>
    </g>
  </svg>
)


export default Heart