// ----------------------------------
// IMAGES

const cloudinary = `https://res.cloudinary.com/das8diykm/image/upload/g_auto/f_auto/q_auto`
const iconUrl = `${ cloudinary }/portfolio-icons/`
const stitching = `${ cloudinary }SCALEstitching`

export const BROWNPAPER = `${ cloudinary }/stitching/paper.jpg`
export const BLACKPAPER = `${ cloudinary }/stitching/black-paper.jpg`
export const COMIC_FULL = `${ iconUrl }Comic.png`
export const COMIC_TOP = `${ iconUrl }comic-top.png`
export const COMIC_BOTTOM = `${ iconUrl }comic-bottom.png`
export const COMIC_BFFS = `${ iconUrl }comic-long.png`
export const homeBgUrl = `https://images.ctfassets.net/hkpf2qd2vxgx/7uBnRmVd5qt1ZqTjEj1ZF8/a3e8bb245c23bd9bd119faa9214f69dc/campus-8.jpg_w_1952`

export const HOOPS = [
  { url: `${ stitching }/kitchen.png`, secondUrl: `${ stitching }/kitchen-ref.png`, title: 'Project #1: Kitchen', subtitle: '6" hoop' },
  { url: `${ stitching }/quarantine.png`, secondUrl: null, title: 'Quarantine: Self-portrait', subtitle: '6" hoop' },
  { url: `${ stitching }/faroe.png`, secondUrl: `${ stitching }/faroe-ref.png`, title: 'Faroe Islands', subtitle: '6" hoop' },
  { url: `${ stitching }/gold.png`, secondUrl: null, title: 'Pony Boy', subtitle: '4" iron-on patch' },
  { url: `${ stitching }/lemons2.png`, secondUrl: null, title: 'Little denim lemons', subtitle: 'various sizes' },
  { url: `${ stitching }/pets.png`, secondUrl: null, title: "Susan's House", subtitle: '6" hoop' },
  { url: `${ stitching }/airport.png`, secondUrl: null, title: 'Arrivals', subtitle: '6" hoop' },
  { url: `${ stitching }/r_j.png`, secondUrl: null, title: 'Robyn & James', subtitle: '6" multimedia' },
  { url: `${ stitching }/fado.png`, secondUrl: `${ stitching }/fado--ref.png`, title: 'Fado', subtitle: '6" hoop' },
  { url: `${ stitching }/seraphim.png`, secondUrl: null, title: 'Seraphim', subtitle: '6" hoop' },
  { url: `${ stitching }/fruits.png`, secondUrl: null, title: 'Summer fruit', subtitle: '8" hoop' },
  { url: `${ stitching }/b_j.png`, secondUrl: `${ stitching }/b_j-lights.png`, title: 'Becca & Josh', subtitle: '8" multimedia', flipText: [ 'Lights', 'Off' ] },
  { url: `${ stitching }/wedding.png`, secondUrl: null, title: 'Britt & Derek', subtitle: '8" hoop' },
]

// ----------------------------------
// CONTENT

export const services = {
  Frontend: {
    Javascript: `${ iconUrl }JAVASCRIPT.png`,
    React: `${ iconUrl }REACT.png`,
    TypeScript: `${ iconUrl }Typescript.png`,
    Rails: `${ iconUrl }RUBY-ON-RAILS.png`,
    HTML: `${ iconUrl }HTML.png`,
  },
  Backend: {
    GraphQL: `${ iconUrl }GraphQL.png`,
    SQL: `${ iconUrl }SQL.png`,
    Ruby: `${ iconUrl }RUBY.png`,
    Node: `${ iconUrl }nodejs.png`,
    Python: `${ iconUrl }python.png`,
  },
  Design: {
    CSS: `${ iconUrl }CSS.png`,
    Sass: `${ iconUrl }sass.png`,
    Ant: `${ iconUrl }AntDesign.png`,
    Bootstrap: `${ iconUrl }bootstrap.png`,
    Tailwind: `${ iconUrl }Tailwind.png`
  }
}

export const jobs = [
  { role: 'Senior software engineer', institution: 'Lumen Learning', time: 'Feb 2021 - present', location: 'Portland, OR' },
  { role: 'Full-stack software engineer', institution: 'Headbox', time: 'June 2019 - Aug 2020', location: 'London, UK' },
  { role: 'Contract software engineer', institution: 'Sculpture', time: 'Feb 2019 - Mar 2020', location: 'London, UK' },
  { role: 'People operations manager', institution: 'Big Health', time: 'May 2017 - Oct 2018', location: 'San Francisco, CA' }
]

export const schools = [
  { institution: 'Flatiron School', role: 'Software Engineering Bootcamp', time: 'Oct 2018 - Jan 2019', location: 'London, UK' },
  { institution: 'Champlain College', role: 'M.A. Conflict Mediation', time: 'Sep 2016 - Jun 2018', location: 'Burlington, VT' },
  { institution: 'UC Santa Cruz', role: 'B.A. Anthropology, Education', time: 'Sep 2012 - May 2015', location: 'Santa Cruz, CA' },
]

export const portfolioText = {
  CircleTimer: {
    title: 'NPM LIBRARY',
    url: 'https://www.npmjs.com/package/simple-circle-timer',
    urlFace: 'The simple-circle-timer',
    description:
      ' package is a lightweight, easily implemented React countdown timer with circular progress bar around the outside. This timer differs itself from similar packages with the amount of play, pause, and reset control it offers the user.',
  },
  Speedback: {
    title: 'TEAM COMMUNICATION',
    url: 'http://speedback.live',
    urlFace: 'Speedback',
    description:
      ' is a tool to help facilitate communication within teams at work. Primarily designed as a mobile app. Now actively used by teams across two different companies.',
  },
  Photography: {
    title: 'ARTIST PORTFOLIO',
    url: 'http://sample-photography.netlify.com',
    urlFace: 'This website',
    description:
      " was built to showcase the work of a local wildlife photographer. It is optimized for the fastest high-quality image delivery on every page. Works on mobile or desktop views. Includes custom admin page for photographer's file management.",
  },
  Edelstein: {
    title: 'MEDICAL BOOKING',
    url: 'http://susanedelsteinphd.com',
    urlFace: "Dr. Edelstein's page",
    description:
      ' includes professional information and custom appointment booking platform for a psychologist. Integrated with external services to automate tedious processes and keep the patient experience secure and HIPAA-compliant.',
  },
  Evictorbook: {
    title: 'COMMUNITY DATA',
    url: 'http://evictorbook.com/',
    urlFace: 'EvictorBook',
    description:
      ' combines data on property ownership, eviction records, and corporate business filings to identify connections between residential property and their owners in San Francisco. *This app is currently in development and password protected.',
  },
  GameNight: {
    title: 'JUST FOR KICKS',
    url: 'https://www.game-night.app/',
    urlFace: 'Game Night',
    description:
      '\'s MVP was initially built in a few hours to give my family a game to play long-distance during lockdown. After the initial success, I returned to it here and there and added new games, shared sessions, and a chat functionality using websockets.',
  }
}

export const ABOUT = {
  title_top: 'I’m a passionate engineer with a human-oriented background.',
  title_bottom: 'We have a responsibility to use technology for social good.',
  paragraph_top:
    "From an early age, I was most interested in the intricacies of human relationships. But after two degrees and a few years in the professional world of People Ops, I was ready to explore a completely new way of learning and thinking. In 2018, I fell in love with programming, quit my job to pursue it full time, and never looked back. This is the job I've always been looking for. It lets me work through things as a visual thinker and build something out of nothing. I love logical challenges and live most happily in a state of learning new things.",
  paragraph_bottom: [ "In my free time, I like to help coach other prospective engineers who may be at an inherent disadvantage in the field, with organizations such as ", "and, since moving to Portland, the ", ". Because no matter how much I love diving into code every day, what matters most to me is using that power to help people." ],
  medium: 'https://medium.com/@natasha-k/react-state-props-irl-14b98c14981',
  railsGirls: 'https://railsgirls.london/',
  stMungos: 'https://www.mungos.org/our-services/recovery-college/',
  mentorship: 'https://www.pdxwit.org/mentorship/',
}

export const HEADSPACEURL = 'https://www.headspace.com/'

export const HOMELIST = [
  "I'm Natasha Kaczmarczyk.",
  "I'm a programmer.",
  "I'm an artist.",
  "I'm a people person.",
  "I'm available for hire.",
]

export const RESUME = 'https://drive.google.com/file/d/1PtwdxYIbDyN7VlyFPanLxXUt39vM4uKP/view?usp=sharing'

export const LINKEDIN = 'https://www.linkedin.com/in/natashabk/'