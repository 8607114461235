import React, { useState } from 'react'
import NetlifyForm from 'react-netlify-form'
import { HEADSPACEURL } from '../Constants'

const Contact = () => {
  const [ filled, setFilled ] = useState( { name: false, email: false, message: false } )
  const [ attempted, setAttempted ] = useState( false )
  const [ canSubmit, setCanSubmit ] = useState( false )

  const linkedIn = <a href='https://www.linkedin.com/in/natashabk/' target='_blank' rel="noopener noreferrer">LinkedIn</a>

  const notifications = {
    success: {
      headline: 'Sent!',
      text: 'Thanks for reaching out, I\'ll get back to you soon.'
    },
    error: {
      headline: 'Message not sent',
      text: <>Something went wrong. You can try sending it again, or you can contact me on {linkedIn}.</>
    }
  }
  const status = ( type ) => (
    <div className={`status ${ type }`}>
      <h4 className={`status ${ type }`}>{notifications[ type ].headline}</h4>
      <p className='status-text'>{notifications[ type ].text}</p>
    </div>
  )

  const fill = ( e ) => {
    if ( e.target.value ) {
      setFilled( { ...filled, [ e.target.name ]: true } )
      if ( filled.name && filled.email && filled.message ) setCanSubmit( true )
    }
    else {
      setFilled( { ...filled, [ e.target.name ]: false } )
      if ( canSubmit ) setCanSubmit( false )
    }
  }

  const itemClass = ( field ) => {
    const isRed = attempted && !filled[ field ] ? 'red' : ''
    const type = field === 'message' ? 'area' : 'text'
    return ( `form-item ${ type } ${ isRed }` )
  }

  const input = ( field ) => (
    <input className={itemClass( field )} name={field} placeholder={`Your ${ field }`} onChange={fill} />
  )

  const textarea = ( field ) => (
    <textarea className={itemClass( field )} name='message' placeholder='Message' onChange={fill} />
  )

  return (
    <div className='contact'>
      <NetlifyForm name='portfolio-contact' canSubmit={canSubmit}>
        {( { loading, error, success } ) => (
          <div className='contact-form'>
            <h1 className='headline aqua'>
              I'm currently employed at <a href={HEADSPACEURL} target='_blank' rel="noopener noreferrer">Headspace Health</a>, but always open to chat about interesting side projects.
            </h1>
            {success ? status( 'success' ) :
              <>
                {input( 'name' )}
                {input( 'email' )}
                {textarea( 'message' )}
                <div className='button-row'>
                  <button className='sm send' type='submit' onClick={() => setAttempted( true )}>
                    Send
                  </button>
                </div>
                {error && status( 'error' )}
              </>
            }
          </div>
        )
        }
      </NetlifyForm>
    </div>
  )
}

export default Contact