import React, {useState} from 'react'
import Typed from 'react-typed'
import "../Styling/Home.scss";
import Heart from '../Assets/heart.svg.js'
import { HOMELIST } from '../Constants'
import Container from './Container'
import { Link as PageLink } from 'react-router-dom'

const Home = () => {
  const pages = [ 'about', 'experience', 'projects', 'contact' ]
  const [ active, setActive ] = useState()

  const open = (e) => e.target.id === active ? setActive() : setActive(e.target.id)

  return (
    <div className='layout'>
      <div className='full-bg' id={active && 'blur'} onClick={()=> setActive()}>
        <section style={{ margin: 'auto 3%', zIndex: 1 }}>
          <h1 className='home-title'>
            <Typed strings={HOMELIST} typeSpeed={70} backSpeed={20}/>
          </h1>
          <p className='home-text'>
            Full-Stack Software Engineer
            <br />
            Pen & Paper Enthusiast
            <br />
            Remote Office Pal
          </p>
        </section>
      </div>
      <div className='home-menu'>
        {pages.map(page => <button className='menu-item' id={page} onClick={open}>{page}</button>)}
      </div>
      {active && <Container active={active}/>}
      <PageLink to='/stitch'><button className='stitch-link'><Heart/></button></PageLink>
    </div>
  )
}

export default Home
