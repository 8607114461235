import React, {useState, useEffect} from 'react'
import "../Styling/Stitching.scss"
import Forward from '../Assets/forward.svg'
import Back from '../Assets/back.svg'
import Flip1 from '../Assets/flip1.svg'
import Flip2 from '../Assets/flip2.svg'
import {HOOPS} from '../Constants'

const SmCraft = () => {
	const [ inModal, setInModal ] = useState( null )
	const [ viewing, setViewing ] = useState( null )
	const [ loading, setLoading ] = useState( true )

	useEffect( () => {
		if ( inModal !== null ) setViewing( scale( HOOPS[ inModal ].url, false ) )
	}, [ inModal ] )

	const scale = ( url, preview = true, reference = false ) => {
		url = url.split( 'SCALE' )
		if ( reference ) url.splice( 1, 0, 'c_scale,w_0.9' )
		else preview ? url.splice( 1, 0, 'c_scale,w_0.1' ) : url.splice( 1, 0, 'c_scale,w_0.4' )
		return url.join( '/' )
	}

  const flip = (secondUrl) => {
		setLoading( true )
		setViewing( scale( secondUrl, false, true ) )
  }

	const forward = () => {
		setLoading( true )
		setInModal( inModal + 1 )
	}

	const back = () => {
		setLoading( true )
		setInModal( inModal - 1 )
	}

	const hoopPreview = ( img, index ) => (
		<button key={`${ index }`} className='sm-preview' onClick={() => setInModal( index )}>
			<img alt={img.title} src={scale( img.url )} className='sm-preview' />
		</button>
	)

	const modal = () => {
		const {title, subtitle, secondUrl, flipText} = HOOPS[ inModal ]
		const lgUrl = scale( HOOPS[ inModal ].url, false )
		const flipButtonText = flipText ? flipText : ['Photo', 'Thread']

		return (
			<div className='modal'>
				<button className='close-modal' onClick={()=> setInModal(null)}>x</button>
				<h2 className='hoop-name sm'>
					{title}<br/><span className='light sm'>{subtitle}</span>
				</h2>
				<section className={`sm-hoop ${loading ? 'loading' : ''}`}>
					<div className={loading ? 'loader black' : 'hide'}/>
					<img
						alt={title}
						src={viewing}
						className={`sm-hoop ${loading ? 'loading' : ''}`}
						onLoad={() => setLoading( false )}
					/>
				</section>
				<div className='modal-row'>
					<button disabled={inModal === 0} onClick={back} className='move'>
						<Back/>
					</button>
					{secondUrl &&
						(viewing === lgUrl ? (
							<button className='flip sm' onClick={() => flip(secondUrl)}>
								<Flip1 /> {flipButtonText[0]}
							</button>
						) : (
							<button className='flip sm' onClick={() => setViewing( lgUrl )}>
								<Flip2 /> {flipButtonText[1]}
							</button>
					))}
					<button disabled={inModal + 1 === HOOPS.length} onClick={forward} className='move'>
						<Forward/>
					</button>
				</div>
			</div>
		)
	}
	return (
		<div className='sm-list'>
			{HOOPS.map( ( img, idx ) => hoopPreview( img, idx ) )}
			{inModal !== null && modal()}
		</div>
	)
}
export default SmCraft
