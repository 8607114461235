import React from 'react'
import {portfolioText} from '../Constants'

const Projects = () => {
  return (
    <div className='wrap projects'>
      <h1 className='headline peach'>Here are a few examples of my freelance work and extracurriculuars.</h1>
      {Object.values( portfolioText ).map( ( item, idx ) => {
        return (
          <div className='project-card' key={`k-${ idx }`}>
            <a href={item.url} target='_blank' rel="noopener noreferrer">
              <h2 className='project-name'>{item.title}</h2>
            </a>
            <p>
              <a href={item.url} target='_blank' rel="noopener noreferrer">{item.urlFace}</a>
              {item.description}
            </p>
          </div>
        )
      } )}
       <div className='button-row close'>
        <a href='https://github.com/natashabk' target='_blank' rel="noopener noreferrer">
          <button className='github-button sm'>Github</button>
        </a>
      </div>
    </div>
  )
}

export default Projects