import React, { useState, useEffect } from 'react'
import Media from 'react-media'
import { Link } from 'react-router-dom'
import '../Styling/Stitching.scss'
import Flip1 from '../Assets/flip1.svg'
import Flip2 from '../Assets/flip2.svg'
import Back from '../Assets/back.svg'
import { HOOPS } from '../Constants'
import SmCraft from './SmCraft'

const preview = index => ({
  marginTop: `${ index * 40 }px`,
  marginLeft: index % 2 === 0 ? 10 : 0,
})

const Craft = () => {
  const [ viewing, setViewing ] = useState( 2 )
  const [ imgUrl, setImgUrl ] = useState( null )
  const [ loading, setLoading ] = useState( true )
  const [ hasLoaded, setHasLoaded ] = useState( [] )

  useEffect( () => {
    setImgUrl( scale( HOOPS[ viewing ].url, false ) )
  }, [ viewing ] )

  const view = (img, index) => {
    console.log(img, index)
    setViewing( index )
    if ( !hasLoaded.includes( scale( img.url ) ) ) setLoading( true )
  }

  const flip = (secondUrl) => {
    setImgUrl( scale( secondUrl, false ) )
    if ( !hasLoaded.includes( secondUrl ) ) setLoading( true )
  }

  const handleLoad = () => {
    setLoading( false )
    if ( !hasLoaded.includes( imgUrl ) ) setHasLoaded( [ ...hasLoaded, imgUrl ] )
  }

  const hoopPreview = ( img, idx ) => {
    return (
      <button key={`btn${idx}`} className='btn-preview' style={preview(idx)} onClick={()=> view(img, idx)}>
        <img alt={img.title} src={scale( img.url )} className='preview'/>
      </button>
    )
  }

  const scale = ( url, preview = true ) => {
    url = url.split( 'SCALE' )
    preview ? url.splice( 1, 0, 'c_scale,w_0.2' ) : url.splice( 1, 0, 'c_scale,w_0.9' )
    return url.join( '/' )
  }

  const { title, secondUrl, subtitle, flipText } = HOOPS[ viewing ]
  const smUrl = scale( HOOPS[ viewing ].url )
  const lgUrl = scale( HOOPS[ viewing ].url, false )
  const flipButtonText = flipText ? flipText : ['Photo', 'Thread']

  const optimizedImage = title => (
    <>
      <img className={`hoop-img blur ${loading ? 'loading': ''}`} alt={title} src={smUrl} />
      <div className={loading ? 'loader' : 'hide'}/>
      <img className='hoop-img' alt={title} src={imgUrl} onLoad={handleLoad}/>
    </>
  )

  return (
    <div className='background'>
      <h1 className='title'>
        <Link to='/'><button className='back'><Back/></button></Link> Stitching
      </h1>
      <Media queries={{ small: '(max-width: 600px)' }}>
        {matches => matches.small ? <SmCraft /> : (
          <div className='craft'>
            <div className='list-view'>
              {HOOPS.map( ( img, idx ) => hoopPreview( img, idx ) )}
            </div>
            <div className='view'>
              {secondUrl &&
                (imgUrl === lgUrl ? (
                  <button className='flip' onClick={() => flip(secondUrl)}>
                    <Flip1/>{flipButtonText[0]}
                  </button>
                ) : (
                  <button className='flip' onClick={() => setImgUrl(lgUrl)}>
                    <Flip2/>{flipButtonText[1]}
                  </button>
              ) )}
              <div className='img-wrap-lg'>{imgUrl && optimizedImage(title)}</div>
              <h2 className='hoop-name'>{title}<span className='light'>{subtitle}</span></h2>
            </div>
          </div>
          )
        }
      </Media>
      <div className='bottom-row'>
        <p>For enquiries, please use the contact form on the <Link to='/'>main site.</Link></p>
      </div>
    </div>
  )
}
export default Craft
