import React from 'react'
import "../Styling/Pages.scss"
import About from './About'
import Experience from './Experience'
import Projects from './Projects'
import Contact from './Contact'

const Container = ({active}) => {
  return(
    <div className='container' id={active}>
      {active === 'about' && <About/>}
      {active === 'experience' && <Experience/>}
      {active === 'projects' && <Projects/>}
      {active === 'contact' && <Contact/>}
      
    </div>
  )
}

export default Container