import React from 'react'
import { services, jobs, schools, RESUME, LINKEDIN } from '../Constants'

const Experience = () => {
  const card = (row, item) => (
    <div className='card'>
      <img className='icon' src={services[row][item]} alt={item} />
      <p className='caption'>{item}</p>
    </div>
  )

  const list = (items) => (
    items.map((item, idx) => (
      <>
        <div className='exp-item'>
          <h2 className='role'>{item.role}</h2>
          <h2 className='company'>{item.institution}</h2>
        </div>
        <div className='exp-item'>
          <h3 className='time'>{item.time}</h3>
          <h3 className='location'>{item.location}</h3>
        </div>
        {(idx !== items.length - 1) && <div className='divider'/>}
      </>
    ))
  )

  return(
    <div className='wrap'>
      <h1 className='headline blue'>Experience</h1>
      {list(jobs)}

      <div className='specifics'>
        {Object.keys(services).map(row => 
          <div className="skill-row">
            <p className='sm-headline blue'>{row}</p>
            <div className='icon-row'>
              {Object.keys(services[row]).map(item => card(row, item))}
            </div>
            <div className='divider'/>
          </div>
        )}
      </div> 

      <h1 className='headline blue' id='second'>Education</h1>
      {list(schools)}

      <div className='button-row'>
        <a href={LINKEDIN} target='_blank' rel="noopener noreferrer">
          <button className='experience-button sm' id='left'>LinkedIn</button>
        </a>
        <a href={RESUME} target='_blank' rel="noopener noreferrer">
          <button className='experience-button sm'>Resume</button>
        </a>
      </div>
    </div>
  )
}

export default Experience